.mb-none {
  margin-bottom: 0 !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@1,300&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cormorant&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Red Hat Display", sans-serif;
  letter-spacing: 3px;
}

.fheuiaklfh {
  color: #000;
}

.italicFont {
  font-family: "Cormorant Garamond", serif;
  font-size: 1.5rem;
  font-style: italic;
}

.fheauiofbncjk {
  color: #000;
}

.regularCormorant {
  font-family: "Cormorant", serif;
  letter-spacing: 2px;
}

hr {
  opacity: 0.6;
}

.alignNav {
  padding-right: 113px;
}

.languageBar {
  background-color: #8c9bae;
  padding-right: 30px;
  height: 26px;
  z-index: 11;
  top: 0px;
}

.jpBar {
  background-color: #8c9bae;
}

.navbar {
  transition: all 0.5s ease;
  top: 25px;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 10;
}

.navbar-dark {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.85%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.full {
  width: 100% !important;
}

.fullScreen {
  object-fit: cover;
  object-position: top center;
}

.homeVidSize {
  object-fit: cover;
  width: 100%;
  height: 100vh;
}

.semiWhite {
  background-color: rgb(255, 255, 255, 0.8);
  border-radius: 5px;
}

.semiWhite2 {
  display: none;
}

.carouselPadding {
  padding: 6px 3px;
  background-color: rgba(0, 0, 0, 0.4);
}

.react-multi-carousel-item {
  padding-left: 3px;
  padding-right: 3px;
}

.btn-outline-light {
  border-radius: 0;
}
.navLogo {
  height: 5rem;
  transition: height 0.5s ease;
}
.navLogoSmall {
  height: 3.5rem;
  transition: height 0.5s ease;
}
.navbar-brand-main {
  margin-left: 10px;
  margin-right: 10px;
}

.navbar-brand-secondary {
  margin-left: 34px;
  margin-right: 0;
  display: none;
}
.navLogo-secondary {
  height: 3rem;
}
.booknow-secondary {
  display: none;
}

.nav-link {
  color: #fff;
}

.nav-link:hover,
.nav-link:active {
  color: #ddd;
}

.offcanvas {
  background-color: #345d9fb8;
}
.btn-close {
  position: relative;
  opacity: 1;
  top: 5px;
  left: 6px;
  --bs-btn-close-color: #fff;
}

.booknow-main,
.booknow-secondary,
.booknow-cta,
.directionsGo {
  background-color: #ec8134;
  border: none;
  border-radius: 0;
}

.booknow-main:active,
.booknow-secondary:active,
.booknow-cta:active,
.directionsGo {
  background-color: #db7023 !important;
}

.booknow-main:hover,
.booknow-secondary:hover,
.booknow-cta:hover,
.directionsGo {
  background-color: #db7023;
}

.center {
  text-align: center;
}
.verticalCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 90%;
}
.relative {
  position: relative;
}

.footerBG {
  padding-top: 4rem;
  padding-bottom: 2rem;
  line-height: 1.75;
}

.footerBG,
.whaleLevelsBG {
  background-image: url("images/Wave Dark BG.jpg ");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.footerLinks a {
  text-decoration: none;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.footer,
.footer a {
  color: #fff;
}

.footer a {
  text-decoration: none;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
}

.social a {
  display: inline;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 2rem;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.blueBG {
  background-image: url("./images/blue-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.whaleBlueH,
.whaleBlueP {
  color: #03286b;
}

.whaleBlueH {
  font-weight: bold;
}

.whiteBG {
  background-color: white;
}

.bgDH {
  background-image: url("./images/Diamondhead.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left bottom;
}

.darkBlueBG {
  background-color: #3f5986;
}

.greyBG {
  background-image: url("./images/greyWaves.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.decksBG {
  background-image: url("./images/Waves.jpg");
  background-size: auto;
}

.comboInfoBG {
  background-image: url("./images/comboWavesBG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.formInput,
.errorFormInput {
  width: 100%;
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid #999;
}

.errorFormInput {
  border: 2px solid red;
}

.waitMessage {
  font-size: 1.1rem;
  color: rgb(230, 0, 0);
  margin-bottom: 10px;
}

.fullH {
  height: 6rem;
}

.languageButton {
  bottom: 60px;
  right: 10px;
}

.languageButton2 {
  visibility: hidden;
  margin-top: 15px;
}

.directionsGo {
  margin-left: 10px;
  padding-left: 8px;
  padding-right: 8px;
}

.majesticLarge {
  font-size: 70px;
}

.wholeBoat {
  font-size: 18.2px;
}

.wholeBoat2 {
  font-size: 16px;
}

.alohaTowerBG {
  background-image: url("./images/Aloha\ Tower\ BG\ Blue.jpg");
  background-size: auto 75%;
  background-position: bottom right 100px;
  background-repeat: no-repeat;
  background-color: #415f83;
}

.ticker {
  height: 32px;
  top: 0;
}

.groupsCarousel {
  margin-top: 26px;
  height: calc(100vh - 26px);
}

.charterCarousel {
  height: calc(100vh - 26px);
}

.navPos {
  /* top: 58px; */
}

.homeTextOverlayVideo {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  font-size: 7rem;
}

.islandRight {
  background-color: #00296f;
}

.leftRightText .fs-5 {
  font-size: 1.2rem !important;
}

.islandRight,
.islandRight h2,
.islandRight h3,
.islandRight p {
  color: white;
}

.islandRightWhite {
  background-color: white;
}

.islandRightWhite .narrationBtn {
  background-color: #f3f3f3;
  border: 1px solid #d5d5d5;
}

.islandRightWhite,
.islandRightWhite h2,
.islandRightWhite h3,
.islandRightWhite p {
  color: #00296f;
}

@media (max-width: 456px) {
  .majesticLarge {
    font-size: 56px;
  }
  .wholeBoat {
    font-size: 13.8px;
  }
  .wholeBoat2 {
    font-size: 12px;
  }
}

@media (max-width: 575px) {
  .bannerText {
    font-size: 0.9rem !important;
  }
}

@media (max-width: 991px) {
  .navbar-brand-main,
  .booknow-main {
    display: none;
  }
  .navbar-brand-secondary,
  .booknow-secondary {
    display: block;
  }
  .departCol {
    border-right: none !important;
  }
  .navFontSize {
    font-size: 1.3rem;
  }
  .homeTextOverlayVideo {
    font-size: 3rem;
  }
}

@media (max-width: 767px) {
  .verticalCenter {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: none;
    transform: none;
    width: 100%;
  }
  .fullH {
    height: 4rem;
  }
  .mobileHide {
    display: none;
  }
  .mobileShow {
    display: block;
  }
  .alohaTowerBG {
    background-size: auto 50%;
    background-position: bottom right;
  }
  .homeTextOverlayVideo {
    font-size: 2rem;
  }
  /* .bannerText {
    font-size: 0.8rem !important;
  } */
}

@media (min-width: 768px) {
  .me-lg-6 {
    margin-right: 0 !important;
  }
  .departCol {
    width: 50%;
  }
  .majesticCol {
    width: 50%;
  }
  .exploreCol {
    width: calc(50% - 10px);
    margin-left: 10px;
  }
  .socialCol {
    width: calc(50% - 208px);
    margin-right: 208px !important;
  }
  .mobileShow {
    display: none;
  }
}

@media (min-width: 1200px) {
  .me-lg-6 {
    margin-right: 4rem !important;
  }
  .departCol {
    width: calc(213px + 4rem);
  }
  .majesticCol {
    width: 302px;
  }
  .exploreCol {
    width: 224px;
  }
  .socialCol {
    width: 95px;
    margin-right: 0 !important;
  }
}

@media (min-width: 1400px) {
  .alohaTowerBG {
    background-position: bottom right 300px;
  }
}

@media (min-width: 1450px) {
  .islandRightTextBox {
    width: 75%;
    padding: 0 !important;
  }
}

@media (max-width: 767px) {
  .ThreeIconAdjust {
    margin-top: 50%;
  }
  .ThreeIconAdjust,
  .ThreeIconAdjust2 {
    width: 33%;
  }
  .homeVidSize {
    height: 50vh;
  }
  .semiWhite {
    display: none;
  }
  .semiWhite2 {
    display: block;
  }
  .groupsCarousel {
    height: calc(50vh - 26px);
  }

  .charterCarousel {
    height: calc(50vh - 26px);
  }
}

@media (min-width: 768px) {
  .ThreeIconAdjust,
  .ThreeIconAdjust2 {
    margin-top: 25%;
    width: 26%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .homeTextOverlayVideo {
    font-size: 4rem;
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .homeTextOverlayVideo {
    font-size: 5rem;
  }
}

@media (max-width: 1199px) {
  p.thingsReal.smallText {
    font-size: 1rem !important;
  }
}

@media (min-width: 1200px) and (max-width: 1449px) {
  p.thingsReal,
  a.thingsReal {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  p.thingsReal.smallText {
    font-size: 1rem !important;
  }
}
